import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import slide2Desktop from './images/terrace_desktop.webp';
import slide2Mobile from './images/terrace_mobile.webp';
import slide3Desktop from './images/ground_floor_desktop.webp';
import slide3Mobile from './images/ground_floor_mobile.webp';
import slide4Desktop from './images/ham_machine_desktop.webp';
import slide4Mobile from './images/ham_machine_mobile.webp';
import slide5Desktop from './images/wine_cellar_desktop.webp';
import slide5Mobile from './images/wine_cellar_mobile.webp';
import slide6Desktop from './images/first_floor_desktop.webp';
import slide6Mobile from './images/first_floor_mobile.webp';
import FontAwesome from '../FontAwesome/';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles/css/carousel.css';

const CustomCarousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const carouselItems = [
    {
      id: 6,
      srcDesktop: slide6Desktop,
      srcMobile: slide6Mobile,
      alt: 'Photo du restaurant'
    },
    {
      id: 4,
      srcDesktop: slide4Desktop,
      srcMobile: slide4Mobile,
      alt: 'Photo de la cuisine'
    },
    {
      id: 3,
      srcDesktop: slide3Desktop,
      srcMobile: slide3Mobile,
      alt: 'Photo de la cuisine'
    },
    {
      id: 5,
      srcDesktop: slide5Desktop,
      srcMobile: slide5Mobile,
      alt: 'Photo de la cuisine'
    },
    {
      id: 2,
      srcDesktop: slide2Desktop,
      srcMobile: slide2Mobile,
      alt: 'Photo de la cuisine'
    }
  ];

  return (
    <Carousel
      className="carousel"
      showArrows={true}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={5000}
      transitionTime={250}
      swipeable={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button type="button" onClick={onClickHandler} title={label} className="carousel__arrows carousel__arrows--left">
            <FontAwesome icon="chevron_left" />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button type="button" onClick={onClickHandler} title={label} className="carousel__arrows carousel__arrows--right">
            <FontAwesome icon="chevron_right" />
          </button>
        )
      }
    >
      {carouselItems.map((item) => (
        <img
          key={item.id}
          src={isMobile ? item.srcMobile : item.srcDesktop}
          alt={item.alt}
          className="carousel__image"
        />
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
