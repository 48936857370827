import React, { useState, useEffect } from 'react';
import FontAwesome from '../../components/FontAwesome';
import StarterImg from './images/265A1161.jpg';
import DishImg from './images/_65A1250.jpg';
import DessertImg from './images/_65A1264.jpg';
import { useTranslation } from 'react-i18next';
import './styles/css/menu.css';

function Menu() {
    return (
        <div id="menu" className="menu">
            <Formulas />
            <Starter />
            <Dish />
            <Dessert />
        </div>
    );
}

function Formulas() {
    const { t } = useTranslation();

    return (
        <section className="formulas section">
            <article className="test">
                <h3>{t('menu.formulas.title')}</h3>
                <p>{t('menu.formulas.description')}</p>
                {t('menu.formulas.prices', { returnObjects: true }).map((price, index) => (
                    <div key={index} className="prix gros">
                        <p>{price.name}</p>
                        <p>{price.price}</p>
                    </div>
                ))}
            </article>
            <article className="menuTH">
                <h3>{t('menu.tableHost.title')}</h3>
                <p>{t('menu.tableHost.price')}</p>
                <p>{t('menu.tableHost.description')}</p>
                <div className="plus gros">
                    <p>{t('menu.tableHost.options')}</p>
                </div>
                <p>{t('menu.tableHost.extra')}</p>
                <p className="leaf">{t('menu.tableHost.vegetarian')} <FontAwesome icon="leaf" /></p>
                <p>{t('menu.tableHost.availability')}</p>
            </article>
            <article className="menuEnfant">
                <h3 className="enfant">{t('menu.childrenMenu.title')}</h3>
                <div className="gros">
                    <p>{t('menu.childrenMenu.options')}</p>
                </div>
                <p>{t('menu.childrenMenu.price')}</p>
                <p>{t('menu.childrenMenu.drinks')}</p>
            </article>
        </section>
    );
}

function Starter() {
    const { t } = useTranslation();

    return (
        <section className="menu__section section">
            <img className="photo" src={StarterImg} alt="Photo d'une entrée" loading="lazy" />
            <article className="menu__section--article starter">
                <h3 className="starter__title">{t('menu.starter.title')}</h3>
                {t('menu.starter.items', { returnObjects: true }).map((item, index) => (
                    <div key={index} className="starter__div">
                        <p>{item.name}</p>
                        <p>{item.price}</p>
                    </div>
                ))}
            </article>
        </section>
    );
}

function Dish() {
    const { t } = useTranslation();
    const [showSection, setShowSection] = useState('poissons');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <section className="menu__section section">
            <article className="menu__section dish">
                <h3 className="dish__title">{t('menu.dish.title')}</h3>
                {isMobile && (
                    <div className="dish__buttons">
                        <button
                            onClick={() => setShowSection('poissons')}
                            className={showSection === 'poissons' ? 'active' : ''}
                        >
                            {t('menu.dish.fish.title')}
                        </button>
                        <button
                            onClick={() => setShowSection('viandes')}
                            className={showSection === 'viandes' ? 'active' : ''}
                        >
                            {t('menu.dish.meat.title')}
                        </button>
                    </div>
                )}
                {(!isMobile || showSection === 'poissons') && (
                    <>
                        <h4>{t('menu.dish.fish.title')}</h4>
                        {t('menu.dish.fish.items', { returnObjects: true }).map((item, index) => (
                            <div key={index} className="dish__div">
                                <p>{item.name}</p>
                                <p>{item.price}</p>
                            </div>
                        ))}
                    </>
                )}
                {(!isMobile || showSection === 'viandes') && (
                    <>
                        <h4>{t('menu.dish.meat.title')}</h4>
                        {t('menu.dish.meat.items', { returnObjects: true }).map((item, index) => (
                            <div key={index} className="dish__div">
                                <p>{item.name}</p>
                                <p>{item.price}</p>
                            </div>
                        ))}
                    </>
                )}
                <br />
                <div className="dish__div">
                    <p>{t('menu.dish.veggie.description')}</p>
                    <p>{t('menu.dish.veggie.price')}</p>
                </div>
            </article>
            <img className="photo" src={DishImg} alt="Photo d'un plat" loading="lazy" />
        </section>
    );
}

function Dessert() {
    const { t } = useTranslation();

    return (
        <section className="menu__section section">
            <img className="photo" src={DessertImg} alt="Photo d'un dessert" loading="lazy" />
            <article className="menu__section--article dessert">
                <div className="dish__div">
                    <p>{t('menu.dessert.cheese.description')}</p>
                    <p>{t('menu.dessert.cheese.price')}</p>
                </div>
                <h3 className="dessert__title">{t('menu.dessert.title')}</h3>
                {t('menu.dessert.items', { returnObjects: true }).map((item, index) => (
                    <div key={index} className="dish__div">
                        <p>{item.name}</p>
                        <p>{item.price}</p>
                    </div>
                ))}
            </article>
        </section>
    );
}


export default Menu;
